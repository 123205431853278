/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

const Content = styled(ParseContent)`
  & tr {
    display: flex;
    flex-wrap: wrap;

    div {
      font-weight: 300;
      font-family: ${(props) => props.theme.font.family.secondary};
    }

    & td {
      @media screen and (max-width: 991px) {
        width: 100% !important;
        padding-bottom: 30px;
      }

      tr {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;

        /* td + td {
          width: 70% !important;
        } */

        td:first-child {
          width: 140px !important;
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <div className="py-lg-5 py-4" />

      <div className="container">
        <Content content={acf.description} />
      </div>

      <div className="py-lg-5 py-3" />
      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        description
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
